<template src='./searchbox.html'></template>

<script>
  
//import router from '@/router/index.js'

export default {
  name: "SearchBox",
  components: {
  },
  beforeDestroy() {
  
  },  
  data() {
    return {

    }
  },  
  mounted() {
  },
  methods: {
  },
}

</script>
