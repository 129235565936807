import Vue from 'vue'
import VueRouter from 'vue-router'

import MainContainer from '@/components/main_container.vue'
import ComponentOne from '@/components/wizard/one.vue'
import ComponentTwo from '@/components/wizard/two.vue'
import ComponentThree from '@/components/wizard/three.vue'
import ComponentFour from '@/components/wizard/four.vue'
import ComponentFive from '@/components/wizard/five.vue'
import ComponentSix from '@/components/wizard/six.vue'
import CompaniesList from '@/components/liste/liste.vue'
import CategoryCompanies from '@/components/liste/category_companies.vue'
//import SectionsContainer from '@/components/sections/sections_container.vue'
//import SearchContainer from '@/components/search/search_container.vue'
//import  from '@/components/wizard/two.vue'
//import  from '@/components/wizard/three.vue'
//import  from '@/components/wizard/four.vue'
//import  from '@/components/wizard/five.vue'
//import  from '@/components/confirm/confirm.vue'
//import  from '@/components/companies/companies.vue'
//import  from '@/components//'

Vue.use(VueRouter)

const routes = [
  {
    component: MainContainer,
    name: 'MainContainer',
    path: '/',
  },
  {
    component: ComponentOne,
    name: 'one',
    path: '/one',
  },
  {
    component: ComponentTwo,
    name: 'two',
    path: '/two',
  },
  {
    component: ComponentThree,
    name: 'three',
    path: '/three',
  },
  {
    component: ComponentFour,
    name: 'four',
    path: '/four',
  },
  {
    component: ComponentFive,
    name: 'five',
    path: '/five',
  },
  {
    component: ComponentSix,
    name: 'six',
    path: '/six',
  },
  {
    component: CompaniesList,
    name: 'companies',
    path: '/companies',
  },
  {
    component: CategoryCompanies,
    name: 'category_companies',
    path: '/category_companies',
  },


/*
  {
    component: SectionsContainer,
    name: 'SectionsContainer',
    path: '/',
  },
  {
    component: SearchContainer,
    name: 'SearchContainer',
    path: '/search_container',
  },
  {
    component: Two,
    name: 'two',
    path: '/two',
  },
  {
    component: Three,
    name: 'Three',
    path: '/three',
  },
  {
    component: Four,
    name: 'Four',
    path: '/four',
  },
  {
    component: Five,
    name: 'Five',
    path: '/five',
  },
  {
    component: Confirm,
    name: 'Confirm',
    path: '/confirm',
  },
  {
    component: Companies,
    name: 'Companies',
    path: '/companies',
  },
  {
    component: ,
    name: '',
    path: '/',
  },
*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.onError((error) => {
  
  if(error.name === 'NavigationDuplicate') {
    
    return
  }
  throw error
})

export default router


