<template src='./six.html'>
</template>

<script>
import router from '@/router/index.js'
import { API_URL } from "@/main"
//import axios from "axios"

import WizardHeader from '@/components/wizard/wizard_header.vue'


export default {
  name: "ComponentFive",
  components: {
    WizardHeader,
  },
  data() {
    return {
      customer_data: {},
      policy: '',
      terms_of_use: '',
    }
  },
  mounted() {

    this.policy = API_URL+"users/GetPolicy/"
    this.terms_of_use = API_URL+"users/GetTermsOfUse/"
  },
  methods: {
    nextStep() {

      sessionStorage.setItem("customer_data", JSON.stringify(this.customer_data))
      router.push({path: "/"})
    }
  },
}

</script>




