<template src='./four.html'>
</template>

<script>
//import router from '@/router/index.js';

import WizardHeader from '@/components/wizard/wizard_header.vue'
import QuestionsList from '@/components/wizard/questions.vue'

export default {
  name: "ComponentFour",
  components: {
    WizardHeader,
    QuestionsList,
  },
  data() {
    return {
      vertical_id: null,
    }
  },
  create() {

    this.vertical_id = sessionStorage.getItem("VerticalId")
  },
  mounted() {

    this.vertical_id = sessionStorage.getItem("VerticalId")
    console.log("*** vertical_id", this.vertical_id)
    //this.showQuestions()
  },
  methods: {
    showQuestions() {
      // Future implementation
    },
  },
}

</script>



