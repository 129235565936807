<template src='./advices.html'></template>

<script>
  
import { BASE_URL } from "@/main"
import { API_URL } from "@/main"
import { MEDIA_URL } from "@/main"
//import { EventBus } from "@/main"
import axios from "axios"

export default {
  name: "AdvicesSection",
  data() {
    return {
      advices: [],
    }
  },
  mounted() {

    const self = this
    const url = API_URL+"advices/AdvicesList/"
    axios(url).then( response => {

      self.advices = response.data
    })
  },
  methods: {
    calcPath(image) {

      image = image.replace("media/", "")
      return MEDIA_URL+"advices/"+image.replace(BASE_URL, "")
    }
  },
}


</script>