<template src='./comments.html'>
  
</template>


<script>
  
import { API_URL } from "@/main"
import axios from "axios"
//import { EventBus } from "@/main"
//import { utilities } from "@/Utility/miscellaneous.js"
//import { MEDIA_URL } from "@/main"

import CommentSheet from "./comment_sheet.vue"

export default {
  name: "CommentsList",
  components: {
    CommentSheet,
  },
  data() {
    return {
      comments: [],
      more_comments: false,
    }
  },
  mounted() {

    this.getComments()
  },
  methods: {
    getComments() {

      const self = this
      const url = API_URL+"users/LeadsCommentsList/"

      axios.get(url).then(response => {
        
        response.data.map(function(value) {
     
          self.comments.push(JSON.parse(value))
        })
      })
    },
    show_more_comments() {

      this.more_comments = true
    }
  }
}



</script>