<template src='./one.html'>
  
</template>

<script>
import router from '@/router/index.js'
import { API_URL } from "@/main"
import axios from "axios"

import { CoolSelect } from 'vue-cool-select'
import WizardHeader from '@/components/wizard/wizard_header.vue'

export default {
  name: "ComponentOne",
  components: {
    CoolSelect,
    WizardHeader,
  },
  data() {
    return {
      job_categories: [],
      job_category_selected: '',      
      selected: "",
      showJobCategories: false,
      suggestion: null,
      suggestions: [],
      test: "",
      selected_vertical: null,
    }
  },
  beforeRouteUpdate(to, from, next) {

    if(to.path === "/one") {
      
      this.resetComponent()
    }
    next()
  },  
  mounted() {

    this.loadSuggestions()
  },
  watch: {
    'selected'(newVal) {

      this.job_category_selected = newVal
    }
  },
  methods: {
    closeWizard() {

      if(this.$route.path === "/") {

        window.location.reload()
      } 
      else {
      
        this.$router.push({ path: "/" })
      }
    },
    async getJobCategories(job_id, job_category) {

      const url =  API_URL+"verticals/GetChildrenByParentName/"+job_category+"/"
      const response = await axios.get(url)      
      this.job_categories = response.data
      this.showJobCategories = true
      this.set_category(job_id)
      const url2 = API_URL+"verticals/VerticalsDetail/"+job_id+"/"
      const response2 = await axios.get(url2)
      this.vertical = response2.data
      sessionStorage.setItem("vertical", JSON.stringify(this.vertical))
    },
    gotoTwo() {

      console.log("gotoTwo()", sessionStorage.getItem("vertical"))
      
      if(sessionStorage.getItem("VerticalId") !== null &&
         sessionStorage.getItem("VerticalId") !== "undefined") {

        sessionStorage.removeItem("kind_id")          
        router.push({ path: "/two" });
      } 
      else {
      
        console.error("VerticalId is not set in sessionStorage")
      }
    },
    async loadSuggestions() {

      const url = API_URL+"verticals/VerticalsListNames/"
      const response = await axios.get(url)
      this.suggestions = response.data
      console.log("loadSuggestions()", this.suggestions)
    },
    resetComponent() {
      
      console.log("Route updated, resetting component")
    },
    set_category(job_category) {
      
      sessionStorage.setItem("VerticalId", job_category)
      sessionStorage.setItem("vertical", job_category)
      console.log("set_category", job_category, sessionStorage.getItem("vertical"))
    },    
  }
}

</script>