<template src='./main_container.html'></template>

<script>
  
import AppHeader from '@/components/app_header/app_header.vue'
import SearchContainer from "@/components/search/search_container.vue"
import SectionsContainer from "@/components/sections/sections_container.vue"
import AppFooter from  "@/components/footer/footer.vue"

export default {
  name: "MainContainer",
  components: {
    AppHeader,
    SearchContainer,
    SectionsContainer,
    AppFooter,
  },
  data() {
    return {
    }
  },  
  mounted() {

  },
  methods: {

  },
}

</script>
