<template src='./five.html'>
</template>

<script>
import router from '@/router/index.js'
//import { API_URL } from "@/main"
//import axios from "axios"

import WizardHeader from '@/components/wizard/wizard_header.vue'


export default {
  name: "ComponentFive",
  components: {
    WizardHeader,
  },
  data() {
    return {
      notes: '',
    }
  },
  mounted() {

  },
  methods: {
    nextStep() {

      sessionStorage.setItem("Notes", this.notes)
      router.push({path: "six"})
    }
  },
}

</script>
