import Vue from 'vue'
import App from './App.vue'

import 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.css'

import 'vue-cool-select/dist/themes/bootstrap.css'
import { CoolSelectPlugin } from 'vue-cool-select'

Vue.use(CoolSelectPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import router from './router/index.js'

//export const BASE_URL = 'http://localhost:8001/'
export const BASE_URL = 'https://back.leadgenerationfactory.com/'
export const API_URL = BASE_URL+'api/'
export const MEDIA_URL = BASE_URL+'media/'
export const FILES_URL = BASE_URL+'media/'
export const EventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
