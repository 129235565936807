<template src="./sections_container.html">
  
</template>

<script>

import SearchBox from "@/components/search/searchbox.vue"
import QuickSearch from "@/components/search/quicksearch.vue"
import CommentsList from "@/components/comments/comments.vue"
import GeneralSection2 from "@/components/general2/general2.vue"
import AdvicesSection from "@/components/advices/advices.vue"
import CommunitySections from "@/components/community/community.vue"
import ListeSection from "@/components/liste/liste.vue"
export default {
  name: "SectionsContainer",
  components: {
    CommentsList,
    SearchBox,
    QuickSearch,
    GeneralSection2,
    AdvicesSection,
    CommunitySections,
    ListeSection,
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
}

</script>
