<template src="./comment_sheet.html">
  
</template>

<script>
  
import { MEDIA_URL } from "@/main"
import moment from 'moment'

export default {
  name: "CommentSheet",
  props: [
    "comment",
  ],
  data() {

    var comment_ = this.comment
    
    if(!comment_.logo) {

      comment_.logo = MEDIA_URL+"no-logo.png"
    }

    comment_.logo
    return {
      imgPreUrl: comment_.logo,
      //imgPreUrl: MEDIA_URL+"logos/"+comment_.logo.replace("http://localhost:8001/",""),
      timeElapsed: 0
    }
  },
  mounted() {

    const now = new Date()
    const creation_date = new Date(this.comment.creation_date)
    const difference = now - creation_date
    this.timeElapsed = parseInt(difference / (1000 * 60 * 60))

    if(this.timeElapsed < 25) {

      this.timeElapsed = this.timeElapsed+" ore fa"
    }
    else if(this.timeElapsed < 47) {

      this.timeElapsed = "1 giorno fa"
    }
    else {

      this.timeElapsed = parseInt(this.timeElapsed/24)+" giorni fa"
    }
  },
  methods: {
    getFormattedDate(date) {

      return moment(date).format("DD-MM-YYYY")
    }
  }
}


</script>