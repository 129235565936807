<template src='./community.html'></template>

<script>
  
import { BASE_URL } from "@/main"
import { API_URL } from "@/main"
import { MEDIA_URL } from "@/main"
import axios from "axios"

export default {
  name: "CommunitySections",
  data() {
    return {
      community: {
        registered_users: "2.1 M",
        shared_ideas: "47 mil",
        questions: "12 mil",
        pictures: "1.3 M",
      },      
      companies: [],
      company: null,
    }
  },
  mounted() {

    const self = this

    const companies_url = API_URL+"users/RegistryListLast20/"    
    axios(companies_url).then( response => {
    
      self.companies = response.data
      self.getCompany()
    })

    //setInterval(function() {
    //  
    //  self.getCompany()
    //}, 10000)      
  },
  methods: {
    calcPath(url) {

      const image = MEDIA_URL+"logos/"+url.replace(BASE_URL, "")
      return image
    },
    getCompany() {

      const index = parseInt(Math.random() * this.companies.length -1)
      this.company = this.companies[index] //(Math.random() * this.companies.length) -1]
    }
  },
}


</script>