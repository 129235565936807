<template src='./two.html'>
</template>

<script>
import router from '@/router/index.js'
import { API_URL } from "@/main"
import axios from "axios"

import WizardHeader from '@/components/wizard/wizard_header.vue'

export default {
  name: "ComponentTwo",
  components: {
    WizardHeader,
  },
  data() {
    return {
      currentIndex: 0,
      group: null,
      vertical: {},
      subverticals: [],
      selectedSubvertical: null,
    }
  },
  computed: {
    currentSubvertical() {
     
      return this.subverticals[this.currentIndex]
    },
  },   
  watch: {
    selectedSubvertical(newValue) {
    
      if(newValue) {
        
        this.nextStep()
      }
    },
  },   
  async mounted() {

    console.log("kind", sessionStorage.getItem("kind_id"))
    console.log("vertical", sessionStorage.getItem("vertical"))

    if(sessionStorage.getItem("kind_id")) {

      console.log("if")
      const url = API_URL+"verticals/VerticalsDetail/"+sessionStorage.getItem("kind_id")+"/"
      const response = await axios.get(url)
      this.vertical = response.data
    }
    else {

      console.log("else")
      this.vertical = JSON.parse(sessionStorage.getItem("vertical"))
    }
    console.log("*** vertical", this.vertical)
    this.loadSubverticals()
    this.$forceUpdate()
  },
  methods: {
    async loadSubverticals() {

      try {

        const url = API_URL+"verticals/VerticalChildrenList/"+this.vertical.id+"/"
        const response = await axios.get(url)
        this.subverticals = response.data
        console.log("subverticals", this.subverticals)
      }
      catch (error) {
        
        console.error("Error fetching subverticals:", error)
      }
    },
    nextStep() {

      if(!this.selectedSubvertical) {
      
        console.error("No subvertical selected!")
        return
      }

      try {
      
        sessionStorage.setItem("subvertical", this.selectedSubvertical)
        sessionStorage.setItem("kind_id", this.selectedSubvertical)

        router.push({ path: "/three" })
      } 
      catch(error) {
      
        console.error("Error during navigation:", error)
    }
    }
  },
}

</script>
