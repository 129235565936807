<template src="./wizard_header.html">
</template>

<script>
import router from '@/router/index.js';

export default {
  name: "WizardHeader",
  methods: {
    closeWizard() {
      
      router.replace({ path: "/" })
    },
    gotohome() {

    },
  },
}
</script>