<template  src='./questions.html'>

</template>

<script>
import router from '@/router/index.js'
import { API_URL } from "@/main";
import axios from "axios";

export default {
  name: "QuestionsList",
  data() {
    return {
      currentIndex: 0,
      isLoading: true,
      questions: [],
    }
  },
  computed: {
    
    currentQuestion() {
    
      return this.questions[this.currentIndex]
    },
  },  
  async mounted() {
    
    this.vertical_id = sessionStorage.getItem("VerticalId")
    await this.getQuestionsList()
    this.$forceUpdate()
    this.isLoading = false
  },
  methods: {
    async getAnswers(questions) {

      for(const question of questions) {

        const url2 = await API_URL+"verticals/AnswersByQuestionList/"+question.id+"/"
        const response2 = await axios.get(url2)
        question.answers = response2.data
        
        if(question.answers.some(answer => answer.kind === 'chk')) {
        
          question.selectedAnswers = []
        } 
        else {
        
          question.selectedAnswers = null // For radio buttons or no answers
        }
      }
      this.questions = [...this.questions]
    },
    async getQuestionsList() {
      
      try {
      
        const url = API_URL+"verticals/QuestionsByVerticalList/"+this.vertical_id+"/"
        const response = await axios.get(url)
        this.questions = response.data
        this.getAnswers(this.questions)
        this.questions = [...this.questions]
      } 
      catch (error) {
      
        console.error("Error fetching questions:", error)
      }
    },
    nextQuestion() {

      if(this.currentIndex < this.questions.length - 1) {
        
        this.currentIndex++
      }
    },  
    nextStep() {

      sessionStorage.setItem("questions", JSON.stringify(this.questions))
      router.push({path: "/five"})
    },
    prevQuestion() {
      
      if(this.currentIndex > 0) {
        
        this.currentIndex--
      }
    },
    updateSelectedAnswers(event, value) {
    
      const isChecked = event.target.checked

      if(isChecked) {
      
        if(!this.currentQuestion.selectedAnswers.includes(value)) { // Add the value if not already in the array
        
          this.currentQuestion.selectedAnswers.push(value)
        }
      } 
      else {
      
        this.currentQuestion.selectedAnswers = this.currentQuestion.selectedAnswers.filter( // Remove the value if unchecked
          (selected) => selected !== value
        )
      }
    }          
  },
}

</script>