<template src='./quicksearch.html'></template>

<script>
  
//import axios from "axios"
//import { API_URL } from "@/main"
import { MEDIA_URL } from "@/main"
//import { EventBus } from "@/main"
//import router from '@/router/index.js'

//import { utilities } from "@/Utility/miscellaneous.js"

export default {
  name: "QuickSearch",
  data() {
    return {
      verticals: [],
    }
  },
  mounted() {
    this.verticals = [
      {icon: "cyan-edilizia.png", name: "Edilizia"},
      {icon: "cyan-caldaie.png", name: "Caldaie e Stufe"},
      {icon: "cyan-finanziamenti.png", name: "Finanziamenti"},
      {icon: "cyan-traslochi.png", name: "Traslochi"},
      {icon: "cyan-infissi.png", name: "Infissi"},
      {icon: "cyan-depurazione.png", name: "Depurazione"},
      {icon: "cyan-formazione.png", name: "Formazione"},
      {icon: "cyan-salute.png", name: "Salute e benessere"},
      {icon: "cyan-ristrutturazioni.png", name: "Ristrutturazioni"},
    ]
  },
  methods: {
    getImgUrl(pic){

      return MEDIA_URL+'verticals/'+pic
    },
    show_search(name) {

      console.log("show_search called with name:", name)
      try {

        this.$router.push({ path: '/one', query: { name: name } });
        console.log("Event setQuickVertical routed with name:", name)
      }
      catch (error) {
 
        console.debug("Error routing setQuickVertical:", error);
      }
    }
  }
}


</script>
















