<template src='./app_header.html'></template>

<script>
  
import { API_URL } from "@/main"
import { EventBus } from "@/main"
//import { utilities } from "@/Utility/miscellaneous.js"
import axios from "axios"
import router from '@/router/index.js'

import UpperList from "@/components/upperlist/upperlist.vue"

export default {
  name: "AppHeader",
  components: {
    UpperList,
  },
  data() {
    return {
      columns: {
        installers: [],
        restructurers: [],
        builders: [],
        artisans: [],
        others: [],
      },
      show_list: false,
      show_upper_companies_list: false,
      external_links: {
        'GuidaPrezzi': "https://www.habitissimo.it/preventivi/ristrutturazioni",
        'Blog': "https://progetti.habitissimo.it/",
        'Community': "https://domande.habitissimo.it/ristrutturazioni", 
      },
      username: '',
    }
  },
  mounted() {

    const self = this
    const url_restructurer = API_URL+"verticals/GetChildrenByParentName/Ristrutturazioni/"
    axios.get(url_restructurer).then(response => {

      self.columns.restructurers = response.data
    })

    const url_tech = API_URL+"verticals/GetChildrenByParentName/Tecnici/"
    axios.get(url_tech).then(response => {

      self.columns.installers = response.data
    })

    const url_builders = API_URL+"verticals/GetChildrenByParentName/Edilizia/"
    axios.get(url_builders).then(response => {

      self.columns.builders = response.data
    })

    const url_artisans = API_URL+"verticals/GetChildrenByParentName/Artigiani/"
    axios.get(url_artisans).then(response => {

      self.columns.artisans = response.data
    })

    const url_others = API_URL+"verticals/GetChildrenByParentName/Altri/"
    axios.get(url_others).then(response => {

      self.columns.others = response.data
    })

    EventBus.$on("ShowProfessionalList", function() {

      self.hideCustomers()
    })

    EventBus.$on("logged", () => {

      this.username = sessionStorage.getItem('username')      
    })
  },
  methods: {
    externals(link) {

      window.location.replace(this.external_links[link])
    },       
    gotoAccessPage() {

      router.push({path: "/acccess_page"})
      //EventBus.$emit("AccessPage", true)
    },
    gotohome() {

      router.push({path: "/"}).catch(() => {})
    },
    hideCustomers() {

      this.show_upper_companies_list = false
    },
    searching() {

      this.$router.replace({ path: "/one", query: { t: Date.now()} }).catch(() => {})
    },
    showCompaniesList(id) {

      sessionStorage.setItem("vertical_id",id)
      this.hideCustomers()
    },
    showCustomers() {

      console.log("showCustomers()")
      this.show_upper_companies_list = true
    }, 
  }
}

</script>