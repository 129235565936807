<template src="./category_companies.html">
</template>

<script>

import { API_URL } from "@/main"
import axios from "axios"

import CompanySheet from "@/components/liste/company_sheet.vue"
import AppHeader from "@/components/app_header/app_header.vue"


export default {
  name: "CategoryCompanies",
  components: {
    CompanySheet,
    AppHeader,
  },
  data() {
    return {
      companies: [],
    }
  },
  mounted() {
  
    this.loadCompanies()
  },
  methods: {
    async loadCompanies() {

      const vertical_id = sessionStorage.getItem("kind_id")
      const url = API_URL+"verticals/VerticalsInterestsList/"+vertical_id+"/"
      const response = await axios.get(url)
      this.companies = response.data
      console.log("** companies", this.companies[0])
    }
  },
}

</script>
