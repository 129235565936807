<template src='./upperlist.html'></template>

<script>

import {API_URL} from '@/main'
//import { MEDIA_URL } from "@/main"
//import { BASE_URL } from "@/main"
import {EventBus} from '@/main'
import axios from 'axios'
import router from '@/router/index.js'

export default {
	name: 'UpperList',
	data() {
		return {
			lists: [],
			show_upperlist: true,
		}
	},
	mounted() {
	
		const self = this
    //EventBus.$emit('AjaxStart')
    const url = API_URL+"verticals/VerticalsListNames/"
    axios.get(url).then( response => {

      const result = response.data
      for(let index=0;index < result.length;index++) {

        self.lists.push(new Array(result[index],result[index+1]))
        index = index + 1
        //EventBus.$emit('AjaxComplete')
        EventBus.$emit('HideSpinner')
      }       
    })
	},
	methods: {
    gotoList(kind, id) {

      sessionStorage.removeItem("VerticalId")
      sessionStorage.setItem("kind_id", id)
      router.push({path: "/category_companies"})
      this.show_upperlist = false
    },
	}
}

</script>
