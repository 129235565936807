<template src="./footer.html"></template>

<script>
export default {
  name: "AppFooter",
  methods: {
    useTerms() {

      /*** TODO: Page for terms of use ***/
    },
    privacyPolicy() {

      /*** TODO: Page for privacy policy ***/
    },
    cookies() {

      /*** TODO: Page for cookies ***/
    },
 },
}

</script>

<style>
footer {
  position: sticky;
  bottom: 0;
  display: flex;
  align-content: center;
  background-color: #262b2f;
  color: white;
  width: 100%;
  height: 50px; /* Set the fixed height of the footer here */
}
</style>
