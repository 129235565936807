<template src='./liste.html'></template>


<script>
  
import { API_URL } from "@/main"
import { MEDIA_URL } from "@/main"
import { BASE_URL } from "@/main"
//import { EventBus } from "@/main"
import axios from "axios"
import router from '@/router/index.js'


export default {
  name: "ListeSection",
  data() {
    return {
      professionals: [],
      quotes: [],
      show_professionals: false,
      show_quote: false,
    }
  },
  mounted() {

    const self = this
    const url = API_URL+"verticals/VerticalsListNames/"
    axios.get(url).then( response => {

      const result = response.data

      for(let index=0;index < result.length;index++) {

        self.professionals.push(new Array(result[index],result[index+1]))
        index = index + 1
      }       
    })
  },
  methods: {
    getImageUrl(image) {

      image = image.replace("media/", "")
      return MEDIA_URL+"verticals/"+image.replace(BASE_URL, "")
    },
    gotoList(kind, id) {

      sessionStorage.removeItem("VerticalId")
      sessionStorage.setItem("kind_id", id)
      router.push({path: "/category_companies"})
      this.show_upperlist = false      
    },
    showHide(kind) {

      if(kind == 'professionals') {

        this.show_professionals = !this.show_professionals
        this.show_quote = false
      }
      else if(kind == 'quotes') {

        this.show_professionals = false
        this.show_quote = !this.show_quote
      }
    },    
  },
}

</script>