<template src='./general2.html'></template>

<script>

import { MEDIA_URL } from "@/main"

export default {
  name: "GeneralSection2",
  data() {
    return {      
      external_links: {
        'CompaniesList': "https://www.habitissimo.it/aziende/ristrutturazioni",
        'PricesGuide': "https://www.habitissimo.it/preventivi/ristrutturazioni",
        'CommunityQuestions': "https://domande.habitissimo.it/ristrutturazioni",
        'PhotoGallery': "https://foto.habitissimo.it/ristrutturazioni",
        'IdeasAndProjects': "https://progetti.habitissimo.it/",
        'SectorPrices': "https://www.habitissimo.it/preventivi/ristrutturazioni",
        'PrivatesSupport': "https://www.habitissimo.it/supporto/s/topic/0TOTf0000000te2OAA/assistenza-ai-privati",
        'ContactUs': "https://www.habitissimo.it/evitare-truffe-frodi",
        'Procenter': "https://procenter.habitissimo.it/",
        'DownloadApp': "https://www.habitissimo.it/landing/app-movil-pro/descarga",
        'InvestmentCalculator': "https://www.habitissimo.it/calculadora-profesionales",
        'About': "https://www.habitissimo.it/che_e_habitissimo",
        'Contacts': "https://www.habitissimo.it/contatto",
        'Phone': "https://www.habitissimo.it/telefono",
        'AffiliationProgram': "https://www.habitissimo.it/affiliati",
      },
      images: [],
      search_pros: '',
    }
  },
  mounted() {

    this.loadImages()
  },
  methods: {
    externals(link) {

      window.location.replace(this.external_links[link])
    },
    loadImages() {

      this.images.push({
        'AAA': MEDIA_URL+"AAA.jpg",
        'BBB': MEDIA_URL+"BBB.jpg",
        'CCC': MEDIA_URL+"CCC.jpg",
      })

      console.log("****** images[0]",this.images)
    }
  },
}


</script> 