<template src="./search_container.html"></template>

<script>

import router from '@/router/index.js'

import SearchBox from "./searchbox.vue"

export default {
  name: "SearchContainer",
  components: {
    SearchBox,
  },
  data() {
    return {
      in_search: true,
      show_search: true,
      show_search_button: true,
    }
  },
  mounted() {
  },
  methods: {
    openWizard() {
      
      router.replace({ path: "/one", query: { t: Date.now()} }).catch(() => {})      
    }
  }
}

</script>