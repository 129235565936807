<template src='./three.html'>
</template>

<script>

import router from '@/router/index.js'
import { API_URL } from "@/main"
import axios from "axios"
import { BIcon } from 'bootstrap-vue'

import WizardHeader from '@/components/wizard/wizard_header.vue'

export default {
  name: "ComponentThree",
  components: {
    BIcon,
    WizardHeader,
  },  
  data() {
    return {
      cap: '',
      cities: [],
      city: '',
      province: '',
      provinces: [],
      show_cap: true,
      subvertical: '',
    }
  },
  mounted() {

    this.getSubvertical()
    this.loadProvincesOptions()
  },
  methods: {
    async getSubvertical() {

      const url = API_URL + "verticals/VerticalsDetail/" + sessionStorage.getItem("subvertical") + "/";
      const response = await axios.get(url);
      this.subvertical = response.data.name;  // Set the subvertical name
    },
    handleCapChange() {

      if(this.cap) {
        
        sessionStorage.setItem("cap", this.cap)
        router.push({ path: "/four"})
      }
    },
    async handleCityChange() {

      if(this.city) {

        const url = API_URL+"geographic_areas/CitiesDetail/"+this.city+"/"
        const response = await axios.get(url)
        this.cap = response.data.cap
        sessionStorage.setItem("cap", this.cap)
        router.push({ path: "/four"})
      }
    },
    async loadCityOptions() {

      if(!this.province) {
        
        this.cities = []
        return
      }

      try {
      
        this.cities = []
        const url = API_URL+"geographic_areas/GetCitiesByProvince/"+this.province+"/"
        const response = await axios.get(url)
        this.cities = response.data.map(city => ({
          value: city.id,
          text: city.city,
        }))
      } 
      catch (error) {
      
        console.error("Error loading cities:", error)
      }
    },
    async loadProvincesOptions() {
      
      try {
      
        const url = API_URL + "geographic_areas/ProvincesList/"
        const response = await axios.get(url)
        this.provinces = response.data.map(province => ({
          value: province.id,
          text: province.province,
        }))
      } 
      catch (error) {
      
        console.error("Error loading provinces:", error)
      }
    },
    noCap() {

      this.show_cap = false
    },
  },
}

</script>

<style scoped>
.input-with-icon {
  padding-left: 2.5rem; /* Adjust padding for the icon */
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 0.75rem; /* Adjust icon placement */
  transform: translateY(-50%);
  font-size: 1.25rem; /* Adjust icon size */
  color: gray;
}
</style>