<template src="./company_sheet.html">
</template>

<script>

import { API_URL } from "@/main"
import { MEDIA_URL } from "@/main"
import router from '@/router/index.js'
import axios from "axios"

export default {
  name: "CompanySheet",
  props: ['company_id'],
  data() {
    return {
      company: {
        evaluations: 0,
        logo: '',
        name: '',
        provinces_list: [],
        reviews: [],
        stars: '',
        vertical_interests: [],
      },
    }
  },
  mounted() {
    this.getCompanyData()
  },
  methods: {
    evaluation2stars() {

      this.company.stars = MEDIA_URL + "stars/" + this.company.average_stars + "_stars.png"
    },
    async getCompanyData() {
      
      try {
      
        const url = API_URL + "users/CompanyDetail/" + this.company_id + "/"
        const response = await axios.get(url)
        
        this.company.logo = response.data.logo
        this.company.name = response.data.company
        this.company.average_stars = response.data.average_stars

        const url2 = API_URL + "users/InterestPlaces/" + this.company_id + "/"
        const response2 = await axios.get(url2)

        response2.data.forEach(province => {

          this.company.provinces_list.push(province.city.province.province)
        })
        
        const url3 = API_URL + "users/LeadsComments2List/" + this.company_id + "/"
        const response3 = await axios.get(url3)

        response3.data.forEach(evaluation => {

          this.company.reviews.push({customer: evaluation.customer, comment: evaluation.comment})
        })

        const url4 = API_URL + "verticals/VerticalsInterestsByCustomerList/" + this.company_id + "/"
        const response4 = await axios.get(url4)

        this.company.vertical_interests = response4.data

        this.evaluation2stars()

        response4.data.forEach(review => {
          
          this.company.reviews.push({
            comment: review.comment,
            customer: review.customer,
          })
        })
        console.log("company", this.company)
      } 
      catch (error) {
      
        console.error("Error fetching company data:", error)
      }
    },
    requestQuote() {
    
      router.push({path: "/six"})
    },
    truncateText(text, maxLength) {
      
      if(text.length > maxLength) {
        
        return text.substring(0, maxLength) + "..."
      }

      return text
    },
  },
}
</script>

<style scoped>
.horizontal-list {
  display: flex;
  list-style-type: none; /* Optional: Remove default bullets */
  padding: 0;
  margin: 0;
}

.horizontal-list li {
  margin-right: 15px; /* Optional: Add space between list items */
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>